.err-txt {
    color: red;
    position: relative;
    top: 5px;
    font-size: 16px;
    font-weight: bold;
}

.Input-Box-Profile-Section {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
}

.avatar-sec {

    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
}

.prof-camera {
    position: absolute;
    top: 64px;
    left: 93px;
    font-size: 20px;
    color: #2a2f83;
    /* background-color: blueviolet; */
    padding: 2px;
    border-radius: 50%;
}

.profile_container {

    margin-top: 80px;
    padding-bottom: 200px;
}

.prime-member{
    border: 5px solid #832b67;
    border-radius: 50%;
}
.verifyed{
    position: absolute;
    top: -1px;
    right: -14px;
    color: #17ba3e;
    background: white;
    border-radius: 50%;
    
  
    
}
.card-style{
    min-width: 20rem;
    max-width: 18rem;
    /* border: #832b67 1px solid ; */
    /* max-height: 5rem; */
    /* height: auto; */
    min-height: 30rem;
    margin: 0 15px;
  
    background:#f3f3ff ;
    /* background: linear-gradient(31deg, rgba(131,43,103,0.6054796918767507) 0%, rgba(42,48,134,0.24413515406162467) 100%); */ 
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    user-select: none;
}
.card-list{
    min-height: 20rem;
    max-height: 20rem;
    overflow: scroll;
    list-style-image: url('../../src/asserts/black-tick.png');
}
.active-list{
    list-style-image: url('../../src/asserts/white-tick.png') ;

}
.card-list ul li{
    padding: 8px 0;
    font-size: 14px;
}
.card-style:hover{
background-color: #24337f;
color: #fff !important;

/* transform: rotateY(130deg); */
/* perspective: 100px; */
/* transition: transform 500ms ease-in-out; */
}

.card-style:hover >button{
    background-color: white;
    color: #832b67;
    
}
.card-style:hover .sm-text{
    color: #f3f3ff !important;
}
.card-style:hover .card-list {
    list-style-image: url('../../src/asserts/white-tick.png')
}
.popular{
    background-color: rgb(255 229 152);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
font-weight: 500;
color: #000 !important;
border-radius: 8px;
padding: 3px 12px;
}
.hidden-scroll::-webkit-scrollbar{
    display: none;
}
.bottom-btn{
    border: none;
    background: #832b67;
    color: white;
    padding: 8px 10px;
    border-radius: 8px;
    width: 100%;
   
   
}
.hidden-scroll{
    position: relative;
    scroll-behavior: smooth;
}
.arrow-left{
    position: absolute;
    left: -18px;
    z-index: 3;
    top: 22pc;
    background-color: #fff;
    padding: 5px;
    border-radius: 50%;
}
.arrow-right{
    position: absolute;
    right: -18px;
    z-index: 3;
    top: 22pc;
    background-color: #fff;
    padding: 5px;
    border-radius: 50%;
}

@media (min-width: 768px){
    .sub-list{
        justify-content: center;
    }
}
