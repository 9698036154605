.Login-title-txt{
    margin-top: 50px;
}
.Login-frtext{
    font-size: 32px;
    /* color: #042E60; */
    color: #2a2f83;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.408px;

}
.Login-sectext{
    font-size: 32px;
    /* color: #0158FF; */
    color: #832b67;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.408px;
}
.subtitle-text{
    color: #7F7E89;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.408px;
}
.LoginBox{
    background-color: #FFF;
    border-radius: 15px;
    margin-bottom: 15px;
}
.email-text{
    font-size: 15px;
}
.Input-Box-Section{
    padding-left: 30px;
    padding-right: 30px;
}
.form-labels{
    padding-top: 25px;
    padding-bottom: 10px;
    /*fonts*/
    color: #7F7E89;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.grp-1 > .form-control{
    border: none !important;
    border-bottom: 1px solid #E6E6E6 !important;
    padding-left: 2px !important;
    border-radius: 0 !important;
}
.grp-1 >.form-control:focus{

    /* border: none !important; */
    box-shadow: none !important;
    border-radius: 0 !important;

}

.grp-3 > .form-control{
    border: none !important;
    border-bottom: 1px solid #E6E6E6 !important;
    padding-left: 2px !important;
    border-radius: 0 !important;
    cursor: not-allowed;
}
.grp-3 >.form-control:focus{

    /* border: none !important; */
    box-shadow: none !important;
    border-radius: 0 !important;

}

.grp-2 > .form-control{
    border: none !important;
    border-bottom: 1px solid red !important;
    padding-left: 2px !important;
    border-radius:  0!important;

}
.grp-2 >.form-control:focus{
    /* border: none !important; */
    box-shadow: none !important;
    border-radius: 0 !important;

}
#passwordInput{
    position: relative;
}
.eyevector{
 
    display: flex;
    position: absolute;
    height: 100%;
    top: 60px;
    right: 0;
    cursor: pointer;
  }

.lgn-btn{

    padding: 17px 50px 18px 50px ;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #832b67;
    border-radius: 60px;
    background-color: #832b67;
    color: #FFF;
}
.lgn-btn-down{

    padding: 15px 30px 15px 30px ;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #832b67;
    border-radius: 60px;
    background-color: #832b67;
    color: #FFF;
}
.fpwrd{
    color: #0158FF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.divider-or{
    color:#7F7E89;
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-top: 25px;
}
.dont-have-accnt{
    color: #7F7E89;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.rgternow{
    color:#0158FF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
}
.Errors{

    color: red;
    position: relative;
    top: 7px;
}

/*media query start*/
@media screen and (max-width: 767px) and (min-width: 481px){

    .fpwrd{
        padding-top: 20px;
    }
   
}

@media screen and (max-width: 480px) and (min-width: 320px){
    .Login-frtext{

        font-size: 25px;
    }
    .Login-sectext{
        font-size: 25px;
    }
    .subtitle-text{
        font-size: 14px;
    }
    .fpwrd{
        padding-top: 20px;
    }
    .dont-have-accnt{
        font-size: 14px;
    }
    .rgternow{
        font-size: 14px;
    }
}
/*media query start*/
