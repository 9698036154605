.main-card {
  /* background-color: red; */
  /* font-size: 45px; */
  /* max-width: 50%; */
  border: 1px solid lightgray;
  overflow: hidden;
  background-color: white;
  padding: 15px;
  min-height: 100px;
}
.location_text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: red; */
  -webkit-line-clamp: 2; /* Adjust the number of lines to show before ellipsis */
  transition: all 1s ease;
}
.collapsed {
  -webkit-line-clamp: 1; /* Adjust the number of lines to show before ellipsis */
  /* background-color: #832b67; */
}
.end_btn {
  padding: 15px 36px;
  color: #fff;
  border: none;
}
.lister-p > p {
  margin: 0;
}
.service_text {
  font-size: small;
}

.expanded {
  -webkit-line-clamp: unset; /* Show all lines */
  /* background-color: aqua; */
}
.view-image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.card-img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.list-scroll {
  max-height: 650px;
  overflow-y: scroll;
  /* position: relative; */
  min-height: 650px;
  border-radius: 10px;
}
.list-scroll::-webkit-scrollbar {
  /* display: none; */
  background-color: whitesmoke;
  width: 5px;
}
.list-scroll::-webkit-scrollbar-thumb {
  background-color: #832b67;
}
.list-scroll::-webkit-scrollbar-track {
  height: 5px;
}
.text-response {
  font-size: medium;
}
.main-table {
  position: relative;
}
.track-button {
  padding: 16px 42px;
  color: #fff;
  border: none;
  margin-left: 5px;
}
.banner-agree {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-color: #832b67; */
  z-index: 1;
  cursor: pointer;
}
.iframe::-webkit-scrollbar {
  display: none;
}
.iframe {
  width: 150px;
  height: 150px;
}

.active-check {
  animation: shake 150s 2 linear;
  -moz-animation: shake 150ms 2 linear;
  -webkit-animation: shake 150ms 2 linear;
  -o-animation: shake 150ms 2 linear;
  transition: all;
}
@keyframes shake {
  0% {
    transform: translate(30px, 0);
  }
  50% {
    transform: translate(-30px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.proof_image {
  height: 150px;
  border-radius: 5px;
  width: 150px;
  object-fit: cover;
}
.proof_main {
  position: relative;
}
.proof_lable {
  position: absolute;
  text-align: center;
  /* background: teal; */
  width: 100%;
  color: floralwhite;
  bottom: 0;
  font-weight: bold;
  padding: 6px;
  bottom: -18px;
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
}
.checkbox-agree .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #832b67 !important;
  border-color: #832b67 !important;
}
.list-driver .css-o4b71y-MuiAccordionSummary-content {
  margin: 0 !important;
}
.text-table {
  font-size: small;
}
@media (max-width: 576px) {
  .card-img {
    /* width: 100%; */
    align-self: start;
    object-fit: cover;
    object-position: top center;
    /* height: 45px; */
  }
  .track-button {
    padding: 10px 25px;
  }
  .end_btn {
    padding: 10px 22px;
  }
  .text-response {
    font-size: 11px;
  }
  .service_text {
    font-size: 10px;
  }

  /* Styles for extra small screens */
}
@media (min-width: 577px) and (max-width: 992px) {
  /* Styles for small screens */
  .card-img {
    width: 45px;
    align-self: start;
    height: 45px;
  }
  .track-button {
    padding: 10px 22px;
  }
  .end_btn {
    padding: 10px 20px;
  }
  .text-response {
    font-size: 11px;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Styles for medium screens */
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Styles for large screens */
}
