.pswrd-desc{

    color:#7F7E89;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 30px;
}
.rgster-btn{
    padding: 17px 50px 18px 50px ;
    font-size: 15px;
    border: 1px solid #832b67;
    border-radius: 60px;
    background-color: #832b67;
    color: #FFF;
}

.by-accepting{

    color: #7F7E89;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

/*Below css for verifyAccount*/
.VerityInputs{

    display: flex;
    justify-content: center;
    margin-top: 8rem;
}

#countryInputinput > ::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#countryInputinput > input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}