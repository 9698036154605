.serive-play-ground{
    width: 100%;
    height: auto;
    padding-top: 4%;
    padding-bottom: 1.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.service-box{
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.service-box-hd-txt{
    text-align: center;
}
.service-box-desc{
    color: #797A7C;
    text-align: center;
    font-size: 20px;
    margin-top: 1%;
}
.service-box-play{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 30px;
    margin-top: 4%;
}
.service-box-play-card{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 200px;width: 200px;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    border: .1px solid rgb(188, 188, 188);
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: .5s;
}
.service-box-play-card:hover{
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.service-box-play-card-img{
    width: 100px;
    height: 100px;
}
.service-box-play-card-txt{
    text-align: center;
    color: #2A2F83;
}
@media screen and (max-width: 1500px) and (min-width: 1151px){
    .service-box{
        width: 80%;
    }
}
@media screen and (max-width: 1150px) and (min-width: 768px){
    .service-box{
        width: 85%;
    }
    .service-box-play{
        gap: 20px;
    }
    .service-box-play-card{
        height: 180px;
        width: 180px;
        padding: 20px;
        gap: 10px;
    }
    .service-box-play-card-img{
        width: 80px;
        height: 80px;
    }
}
@media screen and (max-width: 767px) and (min-width: 481px){
    .service-box{
        width: 90%;
    }
    .service-box-play{
        gap: 10px;
    }
    .service-box-play-card{
        height: 180px;
        width: 180px;
        padding: 20px;
        gap: 10px;
    }
    .service-box-play-card-img{
        width: 80px;
        height: 80px;
    }
    .service-box-desc{
        font-size: 18px;
    }
}
@media screen and (max-width: 480px) and (min-width: 361px){
    .service-box{
        width: 95%;
    }
    .service-box-play{
        gap: 7px;
    }
    .service-box-play-card{
        height: 160px;
        width: 160px;
        padding: 20px;
        gap: 10px;
    }
    .service-box-play-card-img{
        width: 60px;
        height: 60px;
    }
    .service-box-desc{
        font-size: 16px;
    }
}
@media screen and (max-width: 360px) and (min-width: 311px){
    .service-box{
        width: 98%;
    }
    .service-box-play{
        gap: 7px;
    }
    .service-box-play-card{
        height: 150px;
        width: 150px;
        padding: 10px;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
    .service-box-play-card-img{
        width: 70px;
        height: 70px;
    }
    .service-box-desc{
        font-size: 16px;
    }
    .service-box-play-card-txt{
        font-size: 14px;
    }
}
@media screen and (max-width: 310px) and (min-width: 280px){
    .service-box{
        width: 99%;
    }
    .service-box-play{
        gap: 5px;
    }
    .service-box-play-card{
        height: 120px;
        width: 120px;
        padding: 5px;
        gap: 8px;
        align-items: center;
        justify-content: center;
    }
    .service-box-play-card-img{
        width: 60px;
        height: 60px;
    }
    .service-box-desc{
        font-size: 14px;
    }
    .service-box-play-card-txt{
        font-size: 13px;
    }
}