.navbar-toggler span {
  transition: opacity 0.5s ease-in-out;
}

.navbar-toggler span.hidden {
  opacity: 0;
}

.logout-header {
  color: #2a2f83;
  border: none;
}

.logout-header h1 {
  color: #2a2f83;
  font-size: 18px;
  text-align: center;
}

.logout-close-btn {
  border: none;
  background-color: #fff;
  color: #2a2f83 !important;
  font-size: 14px;
  padding: 0;
}

.logout-close-btn:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.logout-desc {
  margin: 0;
  color: #2a2f83;
  font-family: "GorditaMedium";
  font-size: 17px;
}

.logout-yes {
  border: none;
  color: #fff;
  background-color: #832b67;
  border-radius: 0.3em;
  padding: 3px 20px;
}

.logout-no {
  border: none;
  color: #2a2f83;
  background-color: #fff;
  border-radius: 0.3em;
  padding: 3px 20px;
}

.copy-right {
  text-align: center;
  color: #fff;
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 5px;
}

.nav-cont {
  background-color: #fff;
  border-radius: 0.25em;
  padding: 13px;
  margin: 20px !important;
  border: 1px solid #bcddff;
}

.navbar-nav {
  margin-top: 0;
  margin-left: auto;
}

.list-item {
  color: #2a2f83 !important;
  text-decoration: none !important;
  font-size: 17px !important;
}

.list-item-act {
  position: relative;
  color: #832b67 !important;
}

.list-item-act::before {
  content: "";
  position: absolute;
  /* top: 50%; */
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the pseudo-element */
  width: 20px;
  height: 0;
  border: 2px solid #2a2f83;
  /* Start with a transparent border */
  transition: width 0.3s, height 0.3s, border-color 0.3s;
}

.navbar-nav .nav-item {
  margin-right: 20px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
  border: none;
}

.navbar-toggler {
  border: none;
}

.header-pro {
  display: none;
}

.header-login-btn {
  background-color: #832b67;
  border-radius: 1.5em;
  padding: 1px 30px !important;
  font-size: 17px;
}

.header-login-btn a:hover {
  color: #fff;
}

.header-login-btn a {
  color: #fff;
}

.footer-cls {
  position: relative;
  padding: 60px 0px 10px 0px;
  background-color: #2a2f83;
  margin-top: 135px;
}

.footer-top {
  padding: 30px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  border-radius: 1.5em;
}

.footer-top-con {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.footer-img {
  display: flex;
  gap: 20px;
}

.footer-head {
  color: #fff;
  font-size: 19px;
  font-weight: 600;
}

.footer-desc {
  color: #fff;
  font-size: 17px;
}

.book-guard-btn {
  border: none;
  background-color: #fff;
  padding: 10px 30px;
  border-radius: 1.5em;
  color: #2a2f83;
}

.footer-list {
  list-style-type: none;
  color: #fff;
  padding-left: 1em;
}

.footer-list a {
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
}

.footer-list-head {
  font-size: 17px !important;
  position: relative;
  padding-bottom: 4px;
}

.footer-list-head::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 15%;
  height: 2px;
  background-color: rgb(214, 205, 205);
}

.footer-top {
  background-color: #832b67;
}

.footer-list li {
  font-size: 14px;
  margin-bottom: 18px;
}

.footer-con {
  margin-top: 14px;
  color: #f1e8e8;
  font-size: 20px;
  font-weight: 400;
  /* animation: animated-text 2s steps(30, end) 1s normal both infinite; */
}
.chat-box-scroll::-webkit-scrollbar {
  /* width: 10px; */
  display: none;
}
@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 472px;
  }
}
.red-txt {
  color: red;
  font-size: 14px;
  font-weight: normal;
}

.work-head {
  margin-top: 30px;
  font-size: 19px;
  color: #2a2f83;
}

.work-head small {
  font-size: 19px;
}

.arr-ico {
  margin-top: -4px;
}

.work-head span {
  color: #832b67;
}

.work-sub-head {
  margin-top: 25px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #2a2f83;
  margin-bottom: 5px;
}

.work-desc {
  text-align: center;
  color: #837e7e;
  font-size: 19px;
}

.work-sub-desc {
  /* margin-top: 25px; */
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 0.75em;
}

.add_card {
  /* width: 70%; */
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  font-weight: 500;
  border-radius: 22px;
  font-size: 12px;
  margin-bottom: 8px;
  cursor: pointer;
}

.pay-card {
  margin: 27px auto 3px auto;
  display: block;
  color: #fff;
  border: none;
  text-align: center;
  border-radius: 0.75em;
  background-color: #832b67;
  padding: 10px 30px;
}

.work-sub-head span {
  color: #832b67;
}

.pay-err {
  color: red;
  margin-top: 10px;
}

.contact-us {
  background: linear-gradient(to right, #fff 50%, transparent);
  border-radius: 0.5em;
  padding: 80px 10px;
}

.con-send-btn {
  border: none;
  color: #fff;
  background-color: #832b67;
  margin: auto;
  display: block;
  text-align: center;
  padding: 10px 50px;
  border-radius: 1em;
}

.contact-conent {
  /* background-color: #2a2f83; */
  background: linear-gradient(to right, #2a2f83 50%, #832b67);
  color: #fff;
  padding: 40px;
  border-radius: 0.75em;
}

.contact-head {
  font-size: 22px;
}

.contact-name input,
.contact-name textarea {
  box-shadow: none;
  border: none;
  border-bottom: 2px solid #eeecec;
  border-radius: 0%;
  resize: none;
  padding: 10px 0px;
}

.contact-name input::placeholder,
.contact-name textarea::placeholder {
  padding: 0;
  color: #c9c4c4;
}

.contact-name input:focus,
.contact-name textarea:focus {
  box-shadow: none;
  border: none;
  border-bottom: 2px solid #e0dede;
}

.border-err input,
.border-err textarea {
  border-bottom: 2px solid red;
}

.telephone{
  margin-right: 30px;
  
}
@media (max-width: 1023px) {
  .navbar-nav .nav-item {
    margin-right: 0;
    margin-left: 10px;
  }

  .logo-img {
    width: 130px;
  }

  .list-item {
    font-size: 13px !important;
  }
}

@media (max-width: 767px) {
  .contact-us {
    background: linear-gradient(to right, #fff, #fff);
  }

  .contact-conent {
    margin-top: 24px;
  }

  .header-login-btn {
    font-size: 15px;
    margin-top: 20px;
  }

  .list-item-act::before {
    display: none;
  }

  .book-guard-btn {
    margin-left: 50px;
    padding: 10px 20px;
    font-size: 15px;
  }

  .footer-head {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .footer-desc {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .footer-cls {
    padding-top: 140px;
  }

  .footer-img {
    gap: 10px;
  }

  .footer-img img {
    object-fit: contain;
    width: 50px;
  }

  .avatar {
    display: none !important;
  }

  .header-pro {
    margin-left: 10px;
    display: block;
  }
  .telephone{
    width: 100%;
    display: flex;
    justify-content: end;
  }
  
  .telephone-content{
    margin-right: 20px;
    width: 100%;
  }
}