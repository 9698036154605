.about-us-play-ground{
    width: 100%;
    height: auto;
    padding-top: 4%;
    padding-bottom: 1.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-us-box{
    /* width: 55%; */
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.about-us-hd-txt{
    text-align: center;
}
.about-us-box-desc{
    color: #797A7C;
    text-align: center;
    font-size: 20px;
    margin-top: 1%;
}
.about-us-box-play{
    display: flex;
    flex-direction: column;
    padding: 4%;
}
.about-us-box-play-box01{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.about-us-box-play-box01-left{
    display: flex;
    flex-direction: column;
    flex: 2;
}
.about-us-box01-left{
    font-size: 26px;
}
.about-us-box-play-box01-left-desc{
    font-size: 20px;
    color: #797A7C;
    margin-top: 2%;
}
.about-us-box-play-box02{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 40px;
    margin: 7% 0;
}
.about-us-box-play-box03{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.about-us-box-play-box03-hd-txt{
    font-size: 40px;
    font-weight: 600;
}
.about-us-box-play-box03-right{
    max-height: 260px;
    width: 100%;
    object-fit: contain;
    align-self: start;
}
@media screen and (max-width: 1500px) and (min-width: 1151px){
    .about-us-box{
        width: 80%;
    }
}
@media screen and (max-width: 1150px) and (min-width: 768px){
    .about-us-box{
        width: 85%;
    }
    .about-us-box01-left{
        font-size: 22px;
    }
    .about-us-box-play-box01-left-desc{
        font-size: 17px;
    }
}
@media screen and (max-width: 767px) and (min-width: 481px){
    .about-us-box{
        width: 90%;
    }
    .about-us-box-play-box01{
        flex-direction: column;
    }
    .about-us-box-play-box01-right{
        height: 300px;
        flex: 1;
    }
    .about-us-box-play-box02{
        flex-direction: column;
    }
    .about-us-box-play-box03{
        flex-direction: column;
        justify-content: center;
    }
}
@media screen and (max-width: 480px) and (min-width: 361px){
    .about-us-box{
        width: 90%;
    }
    .about-us-box01-left{
        font-size: 22px;
    }
    .about-us-box-play-box01-left-desc{
        font-size: 18px;
    }
    .about-us-box-play-box01{
        flex-direction: column;
    }
    .about-us-box-play-box01-right{
        height: 300px;
        flex: 1;
    }
    .about-us-box-play-box02{
        flex-direction: column;
    }
    .about-us-box-play-box03{
        flex-direction: column;
        justify-content: center;
    }
}
@media screen and (max-width: 360px) and (min-width: 270px){
    .about-us-box{
        width: 90%;
    }
    .about-us-box01-left{
        font-size: 22px;
    }
    .about-us-box-play-box01-left-desc{
        font-size: 18px;
    }
    .about-us-box-play-box01{
        flex-direction: column;
    }
    .about-us-box-play-box01-right{
        height: 300px;
        flex: 1;
    }
    .about-us-box-play-box02{
        flex-direction: column;
    }
    .about-us-box-play-box03{
        flex-direction: column;
        justify-content: center;
    }
    .about-us-box-play-box03-right{
        max-height: 200px;
        width: 100%;
        object-fit: contain;
    }
}