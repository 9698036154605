.hire-box{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.hire-box-content{
    width: 70%;
    align-self: center;
    margin: 2%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
}
.hire-box-content-hd{
    font-size: 24px;
}
.content-box-hire{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 1%;
    gap: 6%;
}
.hire-left{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 2%;
}
.hire-right{
    display: flex;
    align-items: center;
    justify-content: center;
}
.hire-right-img{
    width: 100%;
    margin: 10px 0;
    max-height: 300px;
    object-fit: contain;
    align-self: center;
}
.hire-box-content-desc{
    font-size: 20px;
    margin-top: 2%;
}
@media screen and (max-width: 1500px) and (min-width: 1190px){
    .hire-box-content-hd{
        font-size: 22px;
    }
    .content-box-hire{
        gap: 1%;
    }
    .hire-box-content{
        width: 85%;
    }
    .hire-left{
        flex: 2;
    }
    .hire-right{
        flex: 1;
    }
    .hire-left-desc{
        font-size: 14px;
    }
    .hire-right{
        justify-content: start;
    }
    .hire-right-img{
        width: 80%;
    }
    .hire-box-content-desc{
        font-size: 18px;
        margin-top: 2%;
    }
}
@media screen and (max-width: 1189px) and (min-width: 900px){
    .hire-box-content-hd{
        font-size: 18px;
    }
    .content-box-hire{
        gap: 1%;
    }
    .hire-box-content{
        width: 90%;
    }
    .hire-left{
        flex: 2;
        gap: 7px;
    }
    .hire-right{
        flex: 1;
    }
    .hire-left-desc{
        font-size: 12px;
    }
    .hire-right{
        justify-content: start;
    }
    .hire-right-img{
        width: 70%;
    }
    .hire-box-content-desc{
        font-size: 16px;
        margin-top: 2%;
    }
}
@media screen and (max-width: 899px) and (min-width: 768px){
    .hire-box-content-hd{
        font-size: 16px;
    }
    .content-box-hire{
        gap: 1%;
    }
    .hire-box-content{
        width: 94%;
    }
    .hire-left{
        flex: 2;
        gap: 7px;
    }
    .hire-right{
        flex: 1;
    }
    .hire-left-desc{
        font-size: 10px;
    }
    .hire-right{
        justify-content: start;
    }
    .hire-right-img{
        width: 80%;
    }
    .hire-box-content-desc{
        font-size: 14px;
        margin-top: 2%;
    }
}
@media screen and (max-width: 767px) and (min-width: 425px){
    .hire-box-content-hd{
        font-size: 16px;
    }
    .content-box-hire{
        gap: 1%;
    }
    .hire-box-content{
        width: 95%;
    }
    .hire-left{
        flex: 2;
        gap: 7px;
    }
    .hire-right{
        flex: 1;
    }
    .hire-left-desc{
        font-size: 10px;
    }
    .hire-right{
        justify-content: start;
    }
    .hire-right-img{
        display: none;
    }
    .hire-box-content-desc{
        font-size: 14px;
        margin-top: 2%;
    }
    .content-box-hire{
        flex-direction: column;
    }
}
@media screen and (max-width: 424px) and (min-width: 180px){
    .hire-box-content-hd{
        font-size: 14px;
    }
    .content-box-hire{
        gap: 1%;
    }
    .hire-box-content{
        width: 96%;
    }
    .hire-left{
        flex: 2;
        gap: 5px;
    }
    .hire-left-desc{
        font-size: 9px;
    }
    .hire-right{
        display: none;
    }
    .hire-box-content-desc{
        font-size: 12px;
        margin-top: 2%;
    }
    .content-box-hire{
        flex-direction: column;
    }
    
}

@media (max-width: 768px) {
    .guard-signup-input-group,
    .guard-signup-button 
    {
        padding: 10px 20px !important;
    }
    .guard-signup-container{
        flex-direction: column !important;
    }
    .guard-signup-image img{
        margin-left: 0px !important;
    }
    .errorclass{
        padding-left: 20px !important;
    }
    .guard-signup-button 
    {
        margin: 20px !important;
    }
    
      

}
.guard-signup-container {
    box-shadow: 0 4px 8px;
    background: linear-gradient(28deg, rgba(42,47,131,1) 9%, rgba(131,43,103,1) 78%);
  }
  
  .guard-signup-heading {
    text-align: center;
    font-size: 24px;
    color: #fff;
    margin-bottom: 50px;
  }
  
  .guard-signup-form {
    display: flex;
    flex-direction: column;
  }
  
  .guard-signup-input-group {
    padding: 10px 80px;
  }

  .guard-signup-input-group2 {
    margin-bottom: 15px;
    padding: 10px 80px;
  }
  .errorclass{
    padding-left: 80px;
  }

  
  
  .guard-signup-label {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
  }
  
  .guard-signup-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
  }
  
  .guard-signup-file-input {
    padding: 10px;
    font-size: 14px;
  }
  
  .guard-signup-button {
    margin: 20px 80px;
    padding: 10px 30px;
    font-size: 16px;
    border: none;
    border-radius:22px;
    cursor: pointer;
    text-align: center;
    /* background: #fff; */
    background:   #f7c6e8
      
  }
  