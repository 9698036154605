.home-page{
  display: flex;
  flex-direction: column;
}
.list-style-none{
  list-style-type: none;
}

.list-font-size{
  font-size: large;
}
.ul-lister li{
margin: 15px 0;
font-size: large;
padding: 5px;
line-height: 1.5rem;
word-spacing: 3px;
color: #797A7C;
}
.ul-lister li strong {
  color: #2A2F83;
}
.ul-lister{
  padding: 0;
}
.bottom-text{
  line-height: 28px;
  word-break: 3px;
  margin: 8px 0;
}
/* Home banner */
.home-banner{
  width: 100%;
  height: 90vh;
  /* background-image: url('../asserts/home-bg.png'); */
  background-image: url('../asserts/bannerNew1.png');
  /* background-image: url('../asserts/bannerNew.jpeg'); */
  /* background-position:top; */
  background-position:center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;

  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: start;
  color: white;
  box-shadow: rgb(0 0 0 / 45%) 3500px 3500px inset;
}
.btn-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 2%;
}
.sos-btn{
  background-color: transparent;
  color: #CD0333;
  border: 2px solid #CD0333;
  
  font-weight: 600;
  padding: 12px 20px;
  font-size: 17px;
  border-radius: 30px;
  margin-top: 2%;
  outline: none;
  /* border: none; */
  cursor: pointer;
  transition: .5s;
  gap: 5px;
}
.sos-btn:hover{
  color: white;
  border: 2px solid #CD0333;
  background-color: #CD0333;
}
.home-banner-text-box{
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  margin-left: 10%;
  max-width: 45%;
  text-align: left;
  /* align-items: center; */
  align-items: start;

}
.home-banner-text-box01-txt{
  font-size: 60px;
  font-weight: 600;
}
.home-banner-text-box02{
  margin: 3%;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
}
.home-banner-text-box02-txt{
  font-size: 26px;
  /* margin-bottom: 1%; */
}
.home-banner-text-box02-path{
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 1.5%;
  /* margin-bottom: 0; */
}
.home-banner-text-box02-path-check-txt{
  padding: 12px 26px;
  background: rgba(31, 29, 29, 0.5);
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.9px);
  -webkit-backdrop-filter: blur(9.9px);
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.home-banner-text-box03{
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  width: 60%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: start;
}
.home-banner-text-box03-txt{
  font-size: 22px;
  /* line-height: 34px; */
}
.book-btn{
  padding: 12px 18px;
  font-size: 17px;
  color: book-btn;
  color: white;
  background-color: transparent;
  border-radius: 30px;
  margin-top: 2%;
  outline: none;
  border: none;
  cursor: pointer;
  transition: .5s;
  border: 2px solid #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.book-btn:hover{
  /* background: rgba(31, 29, 29, 0.5); */
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.9px);
  -webkit-backdrop-filter: blur(9.9px);
  border: 2px solid #fff;
  color: #282c31;
}
@media screen and (max-width: 2559px) and (min-width: 1501px){
  .home-banner{
    height: 90vh;
  }
  .home-banner-text-box{
    max-width: 45%;
  }
  .home-banner-text-box01-txt{
    font-size: 46px;
  }
  .home-banner-text-box02-txt{
    font-size: 20px;
  }
  .home-banner-text-box02-path{
    width: 90%;
    gap: 10px;
  }
  .home-banner-text-box03{
    width: 70%;
  }
  .home-banner-text-box03-txt{
    font-size: 18px;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1025px){
  .home-banner-text-box{
    max-width: 45%;
  }
  .home-banner-text-box01-txt{
    font-size: 34px;
  }
  .home-banner-text-box02-txt{
    font-size: 18px;
  }
  .home-banner-text-box02-path{
    width: 90%;
    gap: 10px;
  }
  .home-banner-text-box03{
    width: 70%;
  }
  .home-banner-text-box03-txt{
    font-size: 16px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .home-banner-text-box{
    max-width: 55%;
     margin-top: 35%;
  }
  .home-banner-text-box01-txt{
    font-size: 36px;
  }
  .home-banner-text-box02-txt{
    font-size: 18px;
  }
  .home-banner-text-box02-path{
    width: 90%;
    gap: 10px;
  }
  .home-banner-text-box03{
    width: 70%;
  }
  .home-banner-text-box03-txt{
    font-size: 16px;
  }
   .home-banner{
    background-position: right 13% top 3px;
  }
  .ul-lister li, .list-font-size{

    font-size: medium;
    }
}

@media screen and (max-width: 767px) and (min-width: 481px) {
  .home-banner-text-box{
    max-width: 50%;
    margin-top: 50%;

  }
  .home-banner-text-box01-txt{
    font-size: 28px;
  }
  .home-banner-text-box02-txt{
    font-size: 18px;
  }
  .home-banner-text-box02-path{
    width: 100%;
    gap: 7px;
  }
  .home-banner-text-box02-path-check-txt{
    padding: 10px 20px;
    font-size: 14px;
  }

  .home-banner-text-box03{
    width: 80%;
  }
  .home-banner-text-box03-txt{
    font-size: 14px;
  }
  .book-btn, .sos-btn{
    padding: 10px 16px;
    font-size: 15px;
  }
  .home-banner-text-box02 {
    margin: 1%;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
}
  .home-banner{
    background-position: right 13% top 3px;
  }
  .ul-lister li, .list-font-size{

    font-size: small;
    }
}

@media screen and (max-width: 480px) and (min-width: 360px) {
  .ul-lister li, .list-font-size{
  
    font-size: small;
    }
  .home-banner{
    height: 90svh;
    background-position: center;
  }
  .home-banner-text-box{
    max-width: 80%;
       margin-top: 50%;
  }
  .home-banner-text-box02 {
    margin: 5%;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
}
  .home-banner-text-box01-txt{
    font-size: 26px;
  }
  .home-banner-text-box02-txt{
    font-size: 16px;
  }
  .home-banner-text-box02-path{
    width: 100%;
    gap: 5px;
  }
  .home-banner-text-box02-path-check-txt{
    padding: 6px 16px;
    font-size: 14px;
  }

  .home-banner-text-box03{
    width: 90%;
  }
  .home-banner-text-box03-txt{
    font-size: 14px;
  }
  .book-btn,.sos-btn{
    padding: 9px 12px;
    font-size: 14px;
  }
  .spl-work-img{
    height: 190px !important;
  }
  .btn-div{
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 10px;
    margin-top: 2%;
  }
}
@media screen and (max-width: 359px) and (min-width: 280px){
  .home-banner{
    height: 90svh;
    background-position: right 15% top 3px;

  }
  .home-banner-text-box01-txt{
    font-size: 22px;
  }
  .home-banner-text-box02-txt{
    font-size: 12px;
  }
  .home-banner-text-box02-path{
    width: 100%;
    gap: 5px;
  }
  .home-banner-text-box02-path-check-txt{
    padding: 5px 12px;
    font-size: 11px;
  }

  .home-banner-text-box03{
    width: 90%;
  }
  .home-banner-text-box03-txt{
    font-size: 11px;
  }
  .book-btn,.sos-btn{
    padding: 7px 10px;
    font-size: 12px;
  }
  .sos-btn{
    padding: 7px 10px;
    font-size: 12px;
  }
  .spl-work-img{
    height: 160px !important;
  }
  .home-banner-text-box02 {
    margin: 1%;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
  }
  .btn-div{
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 3px;
    margin-top: 2%;
  }
  .home-banner-text-box{
    max-width: 80%;
  }
  .home-banner-text-box02 {
    margin: 5%;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
}
}

/* Service Style */
.home-service{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 5%;
}
.home-service-txt{
  font-size: 24px;
  font-weight: 600;
  color: #2A2F83;
}
.home-service-update-img{
  width: 100%;
  max-height: 400px;
  border-radius: 10px;
  object-fit: contain;
  align-self: center;
  text-align: center;
}
.home-service-txt span{
  color: #832B67;
}
.home-service-dec{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0;
  margin: 0;
}
.home-service-dec-txt{
  margin-right: 10px;
}
.home-service-cat{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 3%;
  gap: 30px;
  width: 80%;
  justify-content: center;
}
.home-service-cat-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: transform .3s;
}
.home-service-cat-box-img{
  /* background-image: url('../asserts/catImg01.png'); */
  width: 250px;
  height: 250px;
  /* border: 1px solid green; */
  /* background-position: center; */
  /* background-size: cover; */
  border-radius: 20px;
  /* margin-bottom: 20px; */
  transition: .5s;

}
.home-service-cat-box-img-txt{
  color: #0E2E60;
}
.home-service-cat-box-img:hover{
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
@media screen and (max-width: 1024px) and (min-width: 769px){
  .home-service-cat{
    gap: 20px;
    width: 85%;
  }
  .home-service-cat-box-img{
    width: 200px;
    height: 200px;
    /* margin-bottom: 15px; */
  }
  /* .home-service-update-img{
    width: 300px;
  } */
}
@media screen and (max-width: 768px) and (min-width: 481px){
  .home-service-txt{
    font-size: 30px;
  }
  .home-service-dec{
    gap: 10px;
  }
  .home-service-dec-txt{
    font-size: 13px;
    margin-right: 9px;
  }
  .home-service-cat{
    gap: 20px;
    width: 90%;
  }
  .home-service-cat-box-img{
    width: 150px;
    height: 150px;
    /* margin-bottom: 15px; */
  }
  .home-service-cat-box-img-txt{
    font-size: 12px;
  }
  /* .home-service-update-img{
    margin-top: 15px;
    width: 350px;
    display: none;
  } */
  .home-service-content-card-box{
    flex-direction: column !important;
  }
}
@media screen and (max-width: 480px) and (min-width: 320px){
  .home-service-txt{
    font-size: 26px;
  }
  .home-service-dec{
    gap: 12px;
  }
  .home-service-dec-txt{
    font-size: 12px;
    margin-right: 10px;
  }
  .home-service-cat{
    gap: 20px;
    width: 90%;
  }
  .home-service-cat-box-img{
    width: 150px;
    height: 150px;
    /* margin-bottom: 15px; */
  }
  .home-service-cat-box-img-txt{
    font-size: 12px;
  }
  /* .home-service-update-img{
    margin-top: 15px;
    width: 350px;
    display: none;
  } */
  .home-service-content-card-box{
    flex-direction: column !important;
  }
}

@media screen and (max-width: 359px) and (min-width: 280px){
  .home-service-txt{
    font-size: 26px;
  }
  .home-service-dec{
    gap: 12px;
  }
  .home-service-dec-txt{
    font-size: 14px;
    margin-right: 10px;
  }
  .home-service-cat{
    gap: 20px;
    width: 90%;
  }
  .home-service-cat-box-img{
    width: 100px;
    height: 100px;
    /* margin-bottom: 15px; */
  }
  .home-service-cat-box-img-txt{
    font-size: 10px;
  }
  /* .home-service-update-img{
    margin-top: 15px;
    width: 350px;
    display: none;
  } */
  .home-service-content-card-box{
    flex-direction: column !important;
  }
}

/* Content */
.home-service-content{
  width: 70%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
  box-shadow: rgba(0, 0, 0, 0.15) 4.4px 4.4px 3.2px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */
  border-radius: 16px;
  margin-top: 3%;
  background-color: #fff;
}
.home-service-content-update{
  box-shadow: none;
  background: transparent;
}
.home-service-update{
  padding:0;
}
.home-service-content-text-box{
  width: 100%;
  margin-bottom: 0%;
}
.home-service-content-text-box-txt{
  color: #2A2F83;
  font-size: 35px;
  font-weight: 500;
}
.home-service-content-card-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.home-service-content-card-box-desc{
  flex: 2;
  padding-right: 5%;
  padding-top: 1%;
  display: flex;
  flex-direction: column;
}
.home-service-content-card-box-desc-txt{
  color: #797A7C;
  font-size: 18px;
}
.home-service-content-card-box-img{
  flex: 1;
  width: 250px;
  height: 300px;
  border-radius: 20px;
}
.color-blue{
  color: #832B67;
}
.home-service-content-label-box{
  background-color: #832B67;
  width: 100%;
  /* height: 400px; */
  border-radius: 20px;
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 2.5% 5%;
}
.home-service-content-label-box-img{
  width: 350px;
  height: 390px;
  position: absolute;
  right: 10px;
  bottom: 0px;
}
.home-service-content-label-box-hd{
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}
.home-service-content-label-box-desc{
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 1% 0;
  margin-bottom: 0px;
  gap: 5%;
}
.home-service-content-label-box-desc01{
  flex: 1;
}
.home-service-content-label-box-desc01-ul{
  display: flex;
  flex-direction: column;
  margin-top: 3%;
}
.home-service-content-label-box-desc01-ul-li{
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
}
.home-service-content-label-box-desc02{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  flex: 2;
}
.home-service-content-label-box-desc02-txt1{
  font-size: 22px;
  text-align: center;
}
.home-service-content-app_name{
  font-size: 26px;
  font-weight: 500;
}
.home-service-content-label-box-desc02-txt2{
  font-size: 34px;
  font-weight: 500;
  text-align: center;
}
.book-btn-guard{
  padding: 12px 18px;
  font-size: 17px;
  color: book-btn;
  color: #832B67;
  background-color: #fff;
  border-radius: 30px;
  margin-top: 3%;
  outline: none;
  border: none;
  cursor: pointer;
  transition: .5s;
  border: 1px solid #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.book-btn-guard:hover{
  background: rgba(31, 29, 29, 0.5);
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.9px);
  -webkit-backdrop-filter: blur(9.9px);
  border: 1px solid #fff;
  color: white;
}
@media screen and (max-width: 1500px) and (min-width: 1023px){
  .home-service-content{
    width: 80%;
  }
  .home-service-content-label-box-desc01-ul-li{
    font-size: 16px;
    margin-bottom: 4px;
  }
  .home-service-content-label-box-desc02-txt1{
    font-size: 20px;
  }
  .home-service-content-app_name{
    font-size: 24px;
    font-weight: 500;
  }
  .home-service-content-label-box-desc02-txt2{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
  }
  .home-service-content-label-box-desc01{
    flex: 1.5;
  }
  .home-service-content-label-box-img {
    width: 300px;
    height: 300px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px){
  .home-service-content{
    width: 90%;
  }
  .home-service-content-card-box-desc-txt{
    font-size: 16px;
  }
  .home-service-content-text-box-txt {
    font-size: 24px;
  }
  .home-service-content-label-box-hd {
    font-size: 20px;
  }
  .home-service-content-label-box-desc01-ul-li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .home-service-content-label-box-img {
    width: 300px;
    height: 300px;
    position: absolute;
    right: 0;
    bottom: 0px;
  }
  .home-service-content-label-box-desc02-txt2 {
    font-size: 24px;
    font-weight: 500;
  }
  .home-service-content-app_name {
    font-size: 23px;
  }
  .home-service-content-label-box-desc02-txt1 {
    font-size: 20px;
  }
  .book-btn-guard{
    padding: 10px 16px;
    font-size: 14px;
  }
  .home-service-content-label-box-desc01{
    flex: 1.5;
  }
}
@media screen and (max-width: 767px) and (min-width: 481px){
  .home-service-content{
    width: 90%;
  }
  .home-service-content-card-box-desc-txt{
    font-size: 14px;
  }
  .home-service-content-text-box-txt {
    font-size: 22px;
  }
  .home-service-content-text-box {
    margin-top: 2%;
}
  .home-service-content-label-box-hd {
    font-size: 18px;
  }
  .home-service-content-label-box-desc01{
    flex: 1.5;
  }
  .home-service-content-label-box-desc01-ul-li {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .home-service-content-label-box-img {
    width: 250px;
    height: 250px;
    position: absolute;
    right: 0;
    bottom: 0px;
  }
  .home-service-content-label-box-desc02-txt2 {
    font-size: 19px;
    font-weight: 500;
  }
  .home-service-content-app_name {
    font-size: 18px;
  }
  .home-service-content-label-box-desc02-txt1 {
    font-size: 16px;
  }
  .book-btn-guard{
    padding: 8px 14px;
    font-size: 13px;
  }
}
@media screen and (max-width: 480px) and (min-width: 361px){
  .home-service-content{
    width: 95%;
  }
  .home-service-content-card-box-img {
    display: none;
}
  .home-service-content-card-box-desc-txt{
    font-size: 14px;
  }
  .home-service-content-text-box-txt {
    font-size: 20px;
  }
  .home-service-content-text-box {
    margin-top: 2%;
}
  .home-service-content-label-box-hd {
    font-size: 16px;
  }
  .home-service-content-label-box-desc01{
    flex: 2;
  }
  .home-service-content-label-box-desc01-ul-li {
    font-size: 9px;
    margin-bottom: 1px;
  }
  .home-service-content-label-box-img {
    width: 200px;
    height: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .home-service-content-label-box-desc02-txt2 {
    font-size: 14px;
  }
  .home-service-content-app_name {
    font-size: 14px;
  }
  .home-service-content-label-box-desc02-txt1 {
    font-size: 12px;
  }
  .book-btn-guard{
    padding: 6px 12px;
    font-size: 12px;
  }
}
@media screen and (max-width: 360px) and (min-width: 280px){
  .home-service-content-label-box-desc{
    flex-direction: column;
  }
  .home-service-content-label-box-desc01-line{
    display: none;
  }
  .home-service-content{
    width: 96%;
  }
  .home-service-content-card-box-img {
    display: none;
}
  .home-service-content-card-box-desc-txt{
    font-size: 14px;
  }
  .home-service-content-text-box-txt {
    font-size: 20px;
  }
  .home-service-content-text-box {
    margin-top: 2%;
}
  .home-service-content-label-box-hd {
    font-size: 16px;
  }
  .home-service-content-label-box-desc01{
    flex: 2;
  }
  .home-service-content-label-box-desc01-ul-li {
    font-size: 9px;
    margin-bottom: 1px;
  }
  .home-service-content-label-box-img {
    width: 200px;
    height: 200px;
    position: absolute;
    right: 0;
    bottom: 0px;
  }
  .home-service-content-label-box-desc02-txt2 {
    font-size: 14px;
  }
  .home-service-content-app_name {
    font-size: 14px;
  }
  .home-service-content-label-box-desc02-txt1 {
    font-size: 12px;
  }
  .book-btn-guard{
    padding: 6px 12px;
    font-size: 12px;
  }
}

/* About */
.home-about{
  display: flex;
  flex-direction: column;
  width: 55%;
  align-self: center;
  /* padding-bottom: 5%; */
  margin-bottom: 2%;
}
.home-about-txt{
  text-align: center;
  font-size: 34px;
  font-weight: 500;
  color: #832B67;
  padding: 1.5% 0;

}
.home-about-desc{
  color: #797A7C;
  font-size: 20px;
  padding: 1.5% 0;
  text-align: center;
}
.home-about-box{
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 30px;
  margin-top: 2%;
}
.home-about-box01, .home-about-box02{
  flex: 1;
}
.home-about-box01-img{
  height: 300px;
}
.home-about-box01-txt-box{
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}
.home-about-box01-txt-box-txt01{
  padding: 2% 0;
  color: #2A2F83;
  font-size: 24px;
}
.home-about-box01-txt-box-txt02{
  font-size: 18px;
  color: #797A7C;
}
@media screen and (max-width: 1500px) and (min-width: 1025px){
  .home-about{
    width: 75%;
    margin-bottom: 2%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px){
  .home-about{
    width: 85%;
    margin-bottom: 2%;
  }
}
@media screen and (max-width: 767px) and (min-width: 481px){
  .home-about{
    width: 85%;
    /* margin-bottom: 17%; */
  }
  .home-about-box{
    flex-direction: column;
  }
}
@media screen and (max-width: 480px) and (min-width: 280px){
  .home-about{
    width: 95%;
    margin-bottom: 5%;
  }
  .home-about-box{
    flex-direction: column;
  }
  .home-about-txt{
    font-size: 24px;
  }
  .home-about-desc {
    font-size: 18px;
}
  .home-about-box01-txt-box-txt01{
    font-size: 20px;
  }
  .home-about-box01-txt-box-txt02{
    font-size: 16px;
  }
}

/* security type */
.home-sec-type{
  width: 70%;
  display: flex;
  flex-direction: column;
  align-self: center;
}
.home-sec-type-txt{
  text-align: left !important;
  color: #2A2F83;
}
.home-sec-img-box{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 10px 0;
}
.home-sec-img-box-img{
  height: 200px;
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
}
.home-sec-desc{
  display: flex;
  padding: 40px 0;
}
.home-sec-desc-txt{
  font-size: 21px;
  color: #797A7C;
}
@media screen and (max-width: 1680px) and (min-width: 1249px){
  .home-sec-type{
    width: 80%;
  }
  .home-sec-img-box-img{
    height: 170px;
    max-width: 250px;
  }
}
@media screen and (max-width: 1248px) and (min-width: 1025px){
  .home-sec-type{
    width: 80%;
  }
  .home-sec-img-box-img{
    height: 140px;
    max-width: 240px;
  }
  .home-sec-desc-txt{
    font-size: 20px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px){
  .home-sec-type{
    width: 85%;
  }
  .home-sec-img-box-img{
    height: 130px;
    max-width: 210px;
  }
  .home-sec-desc-txt{
    font-size: 18px;
  }
  .home-sec-img-04{
    display: none;
  }
}
@media screen and (max-width: 767px) and (min-width: 640px){
  .home-sec-type{
    width: 85%;
  }
  .home-sec-img-box-img{
    height: 110px;
    max-width: 200px;
  }
  .home-sec-desc-txt{
    font-size: 18px;
  }
  .home-sec-img-04{
    display: none;
  }
  .home-sec-desc{
    padding: 20px 0;
  }
}
@media screen and (max-width: 639px) and (min-width: 480px){
  .home-sec-type{
    width: 90%;
  }
  .home-sec-img-box-img{
    height: 110px;
    max-width: 200px;
  }
  .home-sec-desc-txt{
    font-size: 18px;
  }
  .home-sec-img-04, .home-sec-img-01{
    display: none;
  }
  .home-sec-desc{
    padding: 20px 0;
  }
  .custom-head{
    font-size: 15px;
  }
}
@media screen and (max-width: 480px) and (min-width: 343px){
  .home-sec-type{
    width: 95%;
  }
  .home-sec-img-box-img{
    height: 100px;
    max-width: 190px;
  }
  .home-sec-desc-txt{
    font-size: 16px;
  }
  .home-sec-img-04, .home-sec-img-01{
    display: none;
  }
  .home-sec-desc{
    padding: 20px 0;
  }
  .custom-head{
    font-size: 18px;
  }
}
@media screen and (max-width: 342px) and (min-width: 270px){
  .custom-head{
    font-size: 12px;
  }
  .home-sec-type{
    width: 95%;
  }
  .home-sec-img-box-img{
    height: 120px;
    max-width: 190px;
  }
  .home-sec-desc-txt{
    font-size: 16px;
  }
  .home-sec-img-04, .home-sec-img-01, .home-sec-img-03{
    display: none;
  }
  .home-sec-desc{
    padding: 20px 0;
  }
}
.terrific-content-box{
  display: flex;
  align-items: center;
  justify-content: center;
}
.italic-cls-style{
  margin-top: 1%;
  text-align: center;
}
.terrific-content-img-box{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 1%;
}
.terrific-content-img{
  width: 400px;
  border-radius: 10px;
}
.police-img-spl{
  height: 267px !important;
  width: 191px !important;
}
@media screen and (max-width: 1024px) and (min-width: 768px){
  .terrific-content-img{
    width: 300px;
  }
  .police-img-spl{
    height: 199px !important;
    width: 168px !important;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px){
  .terrific-content-img{
    width: 250px;
  }
  .police-img-spl{
    height: 167px !important;
    width: 119px !important;
  }
}
@media screen and (max-width: 574px) and (min-width: 480px){
  .terrific-content-img{
    width: 200px;
  }
  .police-img-spl{
    height: 136px !important;
    width: 95px !important;
  }
  .terrific-content-img-box{
    margin-top: 2%;
  }
}
@media screen and (max-width: 480px) and (min-width: 352px){
  .terrific-content-img-box{
    flex-direction: column;
  }
  .terrific-content-img{
    width: 300px;
  }
  .terrific-content-img-box{
    margin-top: 2%;
  }
  .police-img-spl{
    height: 136px !important;
    width: 95px !important;
  }
  .part-two-none{
    display: none;
  }
}
@media screen and (max-width: 352px) and (min-width: 280px){
  .terrific-content-img-box{
    flex-direction: column;
  }
  .terrific-content-img{
    width: 250px;
  }
  .terrific-content-img-box{
    margin-top: 2%;
  }
  .part-two-none{
    display: none;
  }
}
.home-police{
  display: flex;
  align-items: center;
  width: 50%;
  align-self: center;
  /* margin-bottom: 3%; */
  flex-direction: column;
}
.terrific-content-box-img-box{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
}
.home-police-img{
  width: 150px;
  border-radius: 10px;
}
.extra-home-police{
  max-width: 50%;
  font-size: 18px !important;
}
.home-police-hd{
  align-self: flex-start;
  font-size: 22px !important;
}
@media screen and (max-width: 1024px) and (min-width: 768px){
  .home-police{
    width: 70%;
  }
  .extra-home-police{
    max-width: 80%;
    font-size: 17px !important;
  }
  .home-police-img{
    width: 120px;
  }
  .home-police-hd{
    align-self: flex-start;
    font-size: 20px !important;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px){
  .home-police{
    width: 80%;
  }
  .extra-home-police{
    max-width: 80%;
    font-size: 14px !important;
  }
  .home-police-img{
    width: 100px;
  }
  .home-police-hd{
    align-self: flex-start;
    font-size: 18px !important;
  }
}
@media screen and (max-width: 480px) and (min-width: 352px){
  .home-police{
    width: 95%;
  }
  .extra-home-police{
    max-width: 100%;
    font-size: 14px !important;
  }
  .home-police-img{
    width: 100px;
  }
  .home-police-hd{
    align-self: flex-start;
    font-size: 18px !important;
  }
}
@media screen and (max-width: 352px) and (min-width: 280px){
  .home-police{
    width: 95%;
  }
  .extra-home-police{
    max-width: 100%;
    font-size: 14px !important;
  }
  .home-police-img{
    /* width: 100px; */
    display: none;
  }
  .home-police-hd{
    align-self: flex-start;
    font-size: 18px !important;
  }
}

.home-qa-box{
  width: 70%;
}
.home-txt-div{
  display: flex;
  align-items: start;
  justify-content: space-between;
}
@media screen and (max-width: 769px) and (min-width: 280px){
  .home-txt-div{
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
  }
}

.help-text{
  color: #2A2F83;
}

.help-text > span > a{
  text-decoration: none;
}

.help-text > span > a > .help{
  text-decoration: underline;
}

.initial-content{
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  &:before{
    content: "";
    display: inline-block;
    width: 2px;
    background: #832b67;
    position: absolute;
    left: 3px;
    top: 10px;
    height: calc(100% - 28%);
  }
}

.initial-content li{
  position: relative;
  padding-left: 30px;
  margin-bottom:15px;
  &:before{
    content:"";
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #2A2F83;
    position: absolute;
    left:0;
    top:10px;
    border-radius: 10px;
  }
}

.desc{
  font-size: large;
  line-height: 1.5rem;
  word-spacing: 3px;
  color: #797A7C;
}
.desc-head{
  color: #2A2F83;
}

.login-button{
  padding: 30px 70px !important;
  font-weight: 900 !important;
  color: #fff !important;
  background: rgb(42,47,131) !important;
  background: linear-gradient(279deg, rgba(42,47,131,1) 0%, rgba(131,43,103,1) 100%) !important;
  border-radius: 40px !important;
  z-index: 5;
}

.book-aguard-btn {
  padding: 20px 70px !important;
  font-weight: 900 !important;
  color: #fff !important;
  background: rgb(42,47,131) !important;
  background: linear-gradient(279deg, rgba(42,47,131,1) 0%, rgba(131,43,103,1) 100%) !important;
  border-radius: 40px !important;
  z-index: 5;
  transition: all 0.3s ease-in-out;
  border: none;
}

.book-aguard-btn:hover {
  background: linear-gradient(279deg, rgba(131,43,103,1) 0%, rgba(42,47,131,1) 100%) !important;
  transform: scale(1.05);
}


.initial-content p{
  margin-top: 10px;
}

.home-banner-text-box01{
  width: 162%;
}

@media screen and (max-width: 480px){
  .home-banner-text-box01{
    width: auto;
  }
}

.title {
  display: none;
}

.home-service-cat-box:hover {
  cursor: pointer;
  transform: scale(1.05);
  z-index: 20;
}

.home-service-cat-box:hover .black{
  background: #000;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .5;
  border-radius: 20px;
}

.home-service-cat-box:hover .title {
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  position: absolute;
  text-align: center;
  margin-top: 50%;
  display: block;
}

@media screen and (max-width: 1400px){
  .initial-content{
    &:before{
      display: none;
    }
  }
}

@media screen and (max-width: 320px){
  .home-service-cat-box:hover .title{
    font-size: 0.5em;
  }
}

.arrow-anime{
  margin-top: 5%;
  width: 40% !important;
  height: 40% !important;
  transform: translate3d(0px, 0px, 0px) !important;
  content-visibility: visible !important;
  -webkit-transform: scaleX(-1) !important;
}

.sales-line{
  text-align: center;
  position: absolute;
  top: 20%;
  left: 46%;
  color: #233480;
}
.sales-line a{
  text-decoration: none;
  color: white;
  animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.banner-text{
  width: 100%;
  justify-content: center;
  display: flex;
  text-align: justify;
  font-size: 2rem;
  position: absolute;
  padding: 0px 20px 0px 20px;
  border-radius: 10px;
  animation: float 3s ease-in-out infinite;
  color: white;
  font-size: x-large;
}

.list-bottom{
  margin-bottom: 20px;
}