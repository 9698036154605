.display_badge{
	position:fixed;
	right:0;
	writing-mode:vertical-rl;
	top:50%;
	z-index:3;
}
.whatsapp{
	position: fixed;
	right: 100px;
	bottom:2.5%;
	z-index: 3;
}
.whatsapp > a > img{
	width: 60px;
    height: 60px;
}
@media (max-width: 576px) {
	.display_badge{
		font-size: small;
	}
}
@media screen and (min-width: 738px)and (max-width: 1023px){
	.whatsapp{
		bottom:2%;
	}
}