.payment-con {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-box {
  width: 70%;
  /* height: 70vh; */
  padding-top: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.payment-hd-txt {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.payment-details {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4% 2%;
  margin-top: 3%;
  background: linear-gradient(to right, #2a2f83 30%, #832b67);
  width: 40%;
  border-radius: 20px;
  align-self: center;
  flex-direction: column;
  gap: 5px;
  /* z-index: 10; */
}
.payment-img {
  width: 85px;
  height: 85px;
  object-fit: contain;
  /* border-radius: 50%; */
  /* background: #fff; */
}
.fee-hd {
  color: azure;
  font-size: 26px;
  font-weight: 500;
}
.fee-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 3%;
}
.fee-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: azure;
  gap: 20px;
  width: 100%;
  padding: 0 10%;
}
.fee-keys01 {
  flex: 1;
  text-align: start;
}
.fee-keys02 {
  flex: 0.5;
  text-align: center;
}
.fee-keys03 {
  flex: 0.5;
  text-align: end;
}
.fee-button {
  padding: 2% 4%;
  background-color: azure;
  border-radius: 25px;
  border: none;
  outline: none;
  font-weight: 500;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 1%; */
}
.payment-stripe-img {
  width: 30px;
  margin-right: 10px;
}
@media screen and (max-width: 1540px) and (min-width: 1370px) {
  .payment-box {
    width: 80%;
  }
  .payment-details {
    padding: 4% 2%;
    width: 40%;
    gap: 20px;
  }
}
@media screen and (max-width: 1369px) and (min-width: 1024px) {
  .payment-box {
    width: 90%;
  }
  .payment-details {
    width: 50%;
  }
}
@media screen and (max-width: 1023px) and (min-width: 850px) {
  .payment-box {
    width: 95%;
  }
  .payment-details {
    width: 50%;
  }
}
@media screen and (max-width: 849px) and (min-width: 600px) {
  .payment-box {
    width: 95%;
  }
  .payment-details {
    width: 70%;
    gap: 16px;
  }
  .fee-button {
    padding: 2% 3%;
    font-weight: 500;
    width: 70%;
    font-size: 15px;
  }
  .payment-stripe-img {
    width: 20px;
    margin-right: 8px;
  }
}
@media screen and (max-width: 599px) and (min-width: 481px) {
  .payment-img {
    width: 70px;
    height: 70px;
  }
  .fee-hd {
    font-size: 22px;
  }
  .fee-keys01,
  .fee-keys02,
  .fee-keys03 {
    font-size: 14px;
  }
  .payment-box {
    width: 98%;
  }
  .payment-details {
    width: 80%;
    gap: 16px;
  }
  .fee-button {
    padding: 2% 3%;
    font-weight: 500;
    width: 70%;
    font-size: 15px;
  }
  .payment-stripe-img {
    width: 20px;
    margin-right: 8px;
  }
  .payment-hd-txt {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) and (min-width: 390px) {
  .payment-img {
    width: 60px;
    height: 60px;
  }
  .fee-hd {
    font-size: 18px;
  }
  .fee-keys01,
  .fee-keys02,
  .fee-keys03 {
    font-size: 13px;
  }
  .payment-box {
    width: 98%;
  }
  .payment-details {
    width: 85%;
    gap: 12px;
  }
  .fee-button {
    padding: 2% 3%;
    font-weight: 500;
    width: 70%;
    font-size: 12px;
  }
  .payment-stripe-img {
    width: 16px;
    margin-right: 8px;
  }
  .payment-hd-txt {
    font-size: 14px;
  }
}
@media screen and (max-width: 389px) and (min-width: 270px) {
  .payment-img {
    width: 50px;
    height: 50px;
  }
  .fee-hd {
    font-size: 16px;
  }
  .fee-keys01,
  .fee-keys02,
  .fee-keys03 {
    font-size: 13px;
  }
  .payment-box {
    width: 100%;
  }
  .payment-details {
    width: 95%;
    gap: 10px;
  }
  .fee-button {
    padding: 2% 3%;
    font-weight: 500;
    width: 70%;
    font-size: 11px;
  }
  .payment-stripe-img {
    width: 14px;
    margin-right: 8px;
  }
  .payment-hd-txt {
    font-size: 14px;
  }
}
