.service-image {
  height: 80px;
  object-fit: cover;
}

.service-div {
  /* border: 2px solid #2a2f83; */
  width: fit-content;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  /* margin: 0 5px; */

  /* position: relative; */
}

.mess-input
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 2px solid #832b67 !important;
}
.mess-input .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  /* padding: 9.5px 16px !important; */
}

.service-div:hover {
  /* background-color: #f3b5df; */
  outline: 4px solid #832b67;
}
.service-div:hover > img {
  padding: 5px;
  transition: all 0.5s;
}
.service-div-active {
  /* background-color: #f3b5df; */
  /* padding: 15px; */
  outline: 4px solid #832b67;
}
.service-div-active > img {
  /* height: 50px; */
  padding: 5px;
}
.list-service {
  /* height: 120px; */
  user-select: none;
  margin: 0 5px;
  flex-wrap: wrap;

  align-items: center;
  /* overflow-x: scroll; */
}
.driver-name {
  color: #832b67;
  font-size: medium;
}

.inp-book {
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(33, 37, 41, 0.25);
  color: #2a2f83;
}
.inp-book::placeholder {
  color: rgb(33 37 41 / 75%);
}
.loader-img {
  height: 300px;
  object-fit: contain;
}
.text-loading {
  font-size: 14px;
  text-align: center;
}
.driver-image {
  object-fit: contain;
  height: 100px;
  width: 100%;
  border-radius: 50%;
}
.review_model {
  max-height: 550px;
  overflow-y: scroll;
}
.review_model::-webkit-scrollbar {
  display: none;
}
.some_btn {
  font-size: medium;
  outline: none;
  border: none;
  padding: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}
@media (max-width: 576px) {
  .service-image {
    height: 50px;
  }
  .some_btn {
    font-size: 10px;
  }
  .text-loading {
    font-size: small;
  }
  .loader-img {
    height: 200px;
  }
  .driver-name {
    color: #832b67;
    font-size: 11px;
  }
  .driver-image {
    height: 80px;
    width: 100px;
  }
}
.payment-d {
  /* width: 100%; */
  background: linear-gradient(to right, #2a2f83 30%, #832b67);
}
.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #832b67;
  color: #832b67;
  box-shadow: 9984px 0 0 0 #832b67, 9999px 0 0 0 #832b67, 10014px 0 0 0 #832b67;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #832b67, 9999px 0 0 0 #832b67,
      10014px 0 0 0 #832b67;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #832b67, 9999px 0 0 0 #832b67,
      10014px 0 0 0 #832b67;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #832b67, 9999px 0 0 0 #832b67,
      10014px 0 0 0 #832b67;
  }
  50% {
    box-shadow: 9984px 0 0 0 #832b67, 9999px -10px 0 0 #832b67,
      10014px 0 0 0 #832b67;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #832b67, 9999px 0 0 0 #832b67,
      10014px 0 0 0 #832b67;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #832b67, 9999px 0 0 0 #832b67,
      10014px -10px 0 0 #832b67;
  }
  100% {
    box-shadow: 9984px 0 0 0 #832b67, 9999px 0 0 0 #832b67,
      10014px 0 0 0 #832b67;
  }
}
.date-pick-book .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(33, 37, 41, 0.25) !important;
  border-radius: 0px;
}
.date-pick-book .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #2a2f83 !important;
}
.date-pick-book-error .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid red !important;
  border-radius: 0px;
}
date-pick-book-error .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  color: red !important;
}
